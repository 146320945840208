import { IEnvironment } from './environment.model';
import { defaultEnvironment } from './environment.default';

export const environment: IEnvironment = {
  production: false,
  baseUrl: 'https://ff-online-3.ffsw.dev/srv/api/v1.0/',
  webSocketHostOrigin: 'https://ff-online-3.ffsw.dev/srv',
  onlineLocationOrigin: 'https://ff-online-3.ffsw.dev',
  stNotificationsUrl: 'https://devapi.ffsw.dev/',

  thirdPartyServices: {
    ...defaultEnvironment.thirdPartyServices,
    freshchat: {
      ...defaultEnvironment.thirdPartyServices.freshchat,
      fillColor: '#305e82'
    }
  },

  appSettings: {
    ...defaultEnvironment.appSettings,

    mobileApp: {
      androidUrl: '',
      iosUrl: '',
      galleryUrl: null,
    },
    showEmailNotificationCheckbox: false
  },

  google: {
    ...defaultEnvironment.google
  },

  vendor: {
    company: {
      name: 'WINMALL',
      siteAddress: 'https://winmall-remit.ffsw.dev/{{lang}}/contact-us',
      contactUsExternalUrl: 'https://winmall-remit.ffsw.dev/{{lang}}/contact-us',
      siteLocales: [ ]
    },
    theme: {
      name: 'LAPIS_LAZULI',
      devMode: false,
      autoDarkMode: true,
      isUseCustomLogo: true
    }
  }
};
